export default [
  {
    path: "/nps",
    component: "Layout",
    hidden: false,
    alwaysShow: true,
    meta: {
      title: "Nps配置",
      icon: "el-icon-star-off",
      roles: [0, 1, 2, 3]
    },
    children: [
      {
        path: "recoverit",
        name: "NpsRecoverit",
        component: "nps/recoverit/index",
        meta: {
          title: "Recoverit",
          icon: ""
        }
      },
      {
        path: "recoverit/v2",
        name: "NpsRecoveritNew",
        component: "nps/recoverit/v2/index",
        meta: {
          title: "Recoverit-New",
          icon: ""
        }
      },
      {
        path: "repairit",
        name: "NpsRepairit",
        component: "nps/repairit/index",
        meta: {
          title: "Repairit",
          icon: ""
        }
      },
      {
        path: "wxrecovery",
        name: "NpsWxRecovery",
        component: "nps/wxrecovery/index",
        meta: {
          title: "WxRecovery",
          icon: ""
        }
      }
    ]
  },
  {
    path: "/product",
    component: "Layout",
    hidden: false,
    alwaysShow: true,
    meta: {
      title: "产品配置",
      icon: "el-icon-setting",
      roles: [0, 1, 2, 3]
    },
    children: [
      {
        path: "recoverit",
        name: "Recoverit",
        hidden: false,
        component: "product/recoverit/index",
        meta: {
          title: "Recoverit",
          icon: ""
        }
      },
      {
        path: "repairit",
        name: "Repairit",
        component: "product/repairit",
        hidden: false,
        meta: {
          title: "Repairit",
          icon: ""
        }
      },
      {
        path: "repairitFeature",
        name: "repairitFeature",
        component: "product/repairitFeature",
        hidden: false,
        meta: {
          title: "Repairit feature",
          icon: ""
        }
      },
      {
        path: "wxrecovery",
        name: "WxRecovery",
        component: "product/wxrecovery",
        hidden: false,
        meta: {
          title: "WxRecovery",
          icon: ""
        }
      },
      {
        path: "ubackit",
        name: "Ubackit",
        component: "product/ubackit",
        hidden: false,
        meta: {
          title: "Ubackit",
          icon: ""
        }
      },
      {
        path: "app",
        name: "App",
        component: "product/app",
        hidden: false,
        meta: {
          title: "App",
          icon: ""
        }
      }
    ]
  },
  {
    path: "/feedback",
    component: "Layout",
    hidden: false,
    meta: { 
      title: "用户反馈", 
      icon: "el-icon-bell",
      roles: [0, 1, 2, 3]
    },
    children: [
      {
        path: "",
        name: "Feedback",
        hidden: true,
        component: "feedback",
        meta: { title: "用户反馈", icon: "el-icon-bell" }
      }
    ]
  },
  {
    path: "/settings",
    component: "Layout",
    hidden: false,
    meta: { 
      title: "个人设置", 
      icon: "el-icon-s-custom",
      roles: [0, 1, 2, 3, 4]
    },
    children: [
      {
        path: "",
        name: "Settings",
        hidden: true,
        component: "settings",
        meta: { title: "个人设置", icon: "el-icon-s-custom" }
      }
    ]
  },
  {
    path: "/management",
    component: "Layout",
    hidden: false,
    meta: { 
      title: "权限管理", 
      icon: "el-icon-s-management",
      roles: [0, 1]
    },
    children: [
      {
        path: "",
        name: "权限管理",
        component: "management",
        hidden: true,
        meta: {
          title: "权限管理",
          icon: ""
        }
      }
    ]
  },
  {
    path: "/adsConfigure",
    component: "Layout",
    hidden: false,
    meta: { 
      title: "运营位", 
      icon: "el-icon-s-shop",
      roles: [0, 1, 2, 3, 4]
    },
    children: [
      {
        path: "recoverit",
        name: "adsRecoverit",
        component: "ads/recoverit/index",
        hidden: false,
        meta: {
          title: "Recoverit",
          icon: ""
        }
      },
      {
        path: "repairit",
        name: "adsRepairit",
        component: "ads/repairit/index",
        hidden: false,
        meta: {
          title: "Repairit",
          icon: ""
        }
      },
      {
        path: "ubackit",
        name: "adsUbackit",
        component: "ads/ubackit/index",
        hidden: false,
        meta: {
          title: "Ubackit",
          icon: ""
        }
      }
      // {
      //   path: "wxrecovery",
      //   name: "adsWxRecovery",
      //   component: "ads/wxrecovery/index",
      //   meta: {
      //     title: "WxRecovery",
      //     icon: ""
      //   }
      // }
    ]
  },
  {
    path: "/licenseConfigure",
    component: "Layout",
    hidden: false,
    meta: { 
      title: "产品权益配置", 
      icon: "el-icon-s-finance",
      roles: [0, 1, 2, 3]
    },
    children: [
      {
        path: "",
        name: "licenseConfigure",
        component: "licenseConfigure",
        hidden: false,
        meta: {
          title: "产品权益配置",
          icon: "el-icon-s-finance"
        }
      },
    ]
  },
  {
    path: "/custom",
    component: "Layout",
    hidden: false,
    meta: { 
      title: "兑换码查询", 
      icon: "el-icon-s-tools",
      roles: [0, 1, 2, 3,4]
    } , 
    children: [
      {
        path: "",
        name: "Custom",
        hidden: true,
        component: "custom",
        meta: { title: "兑换码查询", icon: "el-icon-s-tools" }
      },
    ]
  },
  {
    path: "/webConfig",
    component: "Layout",
    hidden: false,
    meta: { 
      title: "Web页运营位", 
      icon: "el-icon-s-shop",
      roles: [0, 1, 2, 3, 4]
    },
    children: [
      {
        path: "recoverit",
        name: "webRecoverit",
        component: "webConfig/recoverit/index",
        hidden: false,
        meta: {
          title: "Recoverit",
          icon: ""
        }
      }, {
        path: "repairit",
        name: "webRepairit",
        component: "webConfig/recoverit/index",
        hidden: false,
        meta: {
          title: "Repairit",
          icon: ""
        }
      }
    ]
  }
];




