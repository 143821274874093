import Vue from 'vue'
import Cookies from 'js-cookie'

import App from './App'
import router from "./router"

import './assets/icons' //
// import './permission' // permission control

import {registerApps, qiankunStart } from './utils/qiankun'

Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
}

// 加载 element 组件库
import Element from 'element-ui'

// 加载 element 组件样式
import './styles/element-variables.scss'

import '@/styles/index.scss'
import store from "./store"
// 全局注册Element组件
Vue.use(Element, {
  size: Cookies.get('size') || 'medium',
})

Vue.config.productionTip = false

// 创建 Vue 根实例
// 将 router 配置到根实例中
new Vue({
  router,
  store,
  render: h => h(App),
  mounted(){
    // qiankunStart()
  }
}).$mount('#app')

registerApps() //注册qiankun应用

setTimeout(() => {
  qiankunStart()
}, 1000)
