import { registerMicroApps, start } from 'qiankun'
import config from '@/qiankun.config'

const { subApps} = config
export function registerApps() {
  try {
    console.log('进来了吗', subApps)
    registerMicroApps(subApps, {
      beforeLoad: [
        app => {
          console.log('before load', app)
        }
      ],
      beforeMount: [
        app => {
          console.log('before mount', app)
        }
      ],
      afterUnmount: [
        app => {
          console.log('before unmount', app)
        }
      ]
    })

  } catch (err) {
    console.log('错误', err)
  }
}

export function qiankunStart(){
   console.log('qiankunStart')
    start({
    //   singular: true,
    // sandbox: {
    //   strictStyleIsolation: true,
    //   experimentalStyleIsolation: true
    // }
    })
}
