const isDevelopment = process.env.NODE_ENV === 'development';
import request from "@/utils/request";
export default{
    subApps:[
        {
            name:'webConfig', //'//localhost:7001' :
            entry: isDevelopment ? '//10.10.134.205:7001':  '/web-app/', // 根据环境设置 entry
            container:'#webConfigApp',
            activeRule:'/webConfig/recoverit',
            props:{
                axiosInstance:request
            }
        }
    ]
}

